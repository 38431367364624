import React from 'react'

// App components
import Button, { styles as buttonStyles } from '../../components/Button'
import InfusionsoftForm from '../../components/InfusionsoftForm'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App content
import yourSmileMatters from '../../assets/your-smile-matters-819x1024.png'
import book from '../../assets/icons/book.png'
import person from '../../assets/icons/person.png'
import money from '../../assets/icons/money.png'
import age from '../../assets/icons/age.png'
import clipboard from '../../assets/icons/clipboard.png'
import graduate from '../../assets/icons/graduate.png'

// App utils
import { getRecaptchaId } from '../../utils/formsConfig'
import { rhythm } from '../../utils/typography'
import { themeStyles, colors, metrics, presets } from '../../utils/theme'

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGrey}`,
    padding: rhythm(1),
    width: '100%',
    maxWidth: '500px',
    ...themeStyles.shadow,
  },
  input: {
    padding: rhythm(1 / 2),
    marginBottom: rhythm(1 / 2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    marginTop: metrics.defaultMargin,
    flexWrap: 'wrap',
  },
  rightContent: {
    padding: metrics.defaultPadding,
    paddingTop: 0,
    minWidth: '300px',
    [presets.Mobile]: {
      minWidth: '450px',
    },
    width: '100%',
    flex: 1,
  },
  image: {
    maxWidth: '200px',
    margin: 0,
    [presets.Mobile]: {
      maxWidth: '300px',
    },
    [presets.Tablet]: {
      maxWidth: '400px',
    },
  },
}

const itemConfig = [
  {
    icon: graduate,
    title: "How Your Child's Smile Impacts Their Future",
    text: "Learn how your child's future can be impacted by simply having a healthy and confident smile.",
  },
  {
    icon: money,
    title: 'How Much Braces Cost',
    text: `Find out how much braces cost, how to make braces affordable, and what "red flags" you should watch out for when visiting orthodontic offices.`,
  },
  {
    icon: clipboard,
    title: 'When Your Child Should First See an Orthodontist',
    text: 'Learn when the American Association of Orthodontists (AAO) recommends that your child first visit an Orthodontist for a check-up.',
  },
  {
    icon: person,
    title: 'Adult Braces Options',
    text: 'Find out why adult braces have become more popular than ever with new options to straighten your teeth that are hardly noticeable during treatment.',
  },
  {
    icon: age,
    title: 'The Right Age for Treatment',
    text: `Read all about the "ideal age" for braces and why the timing of treatment is critical for your child's smile and bite.`,
  },
  {
    icon: book,
    title: 'A Survival Guide for Retainers after Treatment',
    text: 'Learn how long you have to wear your retainer, what to do if you lose or break your retainer, and when to be worried about your teeth shifting throughout life.',
  },
]

const Item = ({ icon, title, text }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '350px',
      margin: '10px',
    }}
  >
    <img src={icon} css={{ maxWidth: '60px', maxHeight: '60px' }} />
    <div
      css={{ display: 'flex', flexDirection: 'column', marginLeft: rhythm(1) }}
    >
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  </div>
)

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={{ ...themeStyles.contentWidth }}>
          <PageTitle title="Books" bannerKey={'RESOURCES'} />
          <div css={styles.container}>
            <img src={yourSmileMatters} css={styles.image} />
            <div css={styles.rightContent}>
              <p css={{ marginBottom: 0, fontWeight: 'bold' }}>
                Everything You Need to Know About Braces and Invisalign
              </p>
              <h1 css={{ ...themeStyles.blueText, marginTop: 0 }}>
                Your Smile Matters
              </h1>
              <p>You asked. We listened.</p>
              <p>
                In this book, you will find easy-to-understand answers to thirty
                of the most commonly asked orthodontic questions that we get on
                a daily basis. So if you are interested in making a more
                informed decision about you or your child’s orthodontic care,
                then this is the book for you.
              </p>

              <InfusionsoftForm css={styles.form} configKey="freeBook">
                <input
                  css={styles.input}
                  ref={(input) => this.firstNameInput}
                  id="inf_field_FirstName"
                  name="inf_field_FirstName"
                  placeholder="First Name"
                  type="text"
                />
                <input
                  css={styles.input}
                  id="inf_field_Email"
                  name="inf_field_Email"
                  placeholder="Email"
                  type="text"
                />

                <div css={{ marginTop: rhythm(1) }}>
                  <button
                    css={buttonStyles.button}
                    id={getRecaptchaId('freeBook')}
                    type="submit"
                  >
                    Get Your FREE Book
                  </button>
                </div>
              </InfusionsoftForm>
            </div>
          </div>
        </div>
        <div css={{ backgroundColor: colors.lightGrey }}>
          <div css={[themeStyles.contentWidth, themeStyles.textPadding]}>
            <h1 css={{ textAlign: 'center', color: colors.brightBlue }}>
              WHAT YOU WILL FIND IN THIS BOOK
            </h1>
            <p css={{ textAlign: 'center' }}>
              Whether your child is ready for the perfect smile - or - you are,
              finding the right doctor and treatment plan can be intimidating.
              That's where this book comes in handy – because your smile
              matters, (really).
            </p>
            <p css={{ textAlign: 'center' }}>
              Breeze through these pages and you'll be in the know on:
            </p>
          </div>
        </div>
        <div
          css={[
            themeStyles.contentWidth,
            themeStyles.textPadding,
            {
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          {itemConfig.map((item) => (
            <Item {...item} />
          ))}
        </div>
        <div css={{ backgroundColor: colors.lightGrey }}>
          <div
            css={[
              themeStyles.contentWidth,
              themeStyles.textPadding,
              { marginTop: rhythm(2) },
            ]}
          >
            <p
              css={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: rhythm(1.2),
              }}
            >
              "This book changed everything I thought I knew about braces for my
              child. If you want to make a more informed decision about your
              child's orthodontic treatment, then this book is a must read!"
            </p>
            <p css={{ textAlign: 'center' }}>
              Melissa P. - Mayo Clinic Physician and Parent of Four Children
            </p>
          </div>
        </div>
        <div
          css={[
            themeStyles.contentWidth,
            themeStyles.textPadding,
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: rhythm(2),
            },
          ]}
        >
          <Button
            text={`Get Your FREE copy of "Your Smile Matters" today`}
            href="#"
            onClick={() => this.firstNameInput.focus()}
          />
          <p css={{ marginTop: rhythm(1) }}>
            Limited to <strong>FIRST 29 ONLY</strong> ($19.95 value)
          </p>
        </div>
      </Layout>
    )
  }
}

export default Page
